/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
  transition: background-color 0.3s ease;
}

.App {
  text-align: center;
  min-height: 100vh;
  transition: background-color 0.3s ease;
}

/* Light mode */
.App.light-mode {
  background-color: #ffffff;
}

/* Dark mode */
.App.dark-mode {
  background-color: #121212;
}

/* Podcast Grid */
.podcast-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  padding: 20px 40px;
  margin: 0 auto;
  max-width: 1800px;
}

/* Podcast Cards */
.podcast-card {
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
}

/* Light mode card styles */
.light-mode .podcast-card {
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.light-mode .podcast-info {
  background: #ffffff;
  color: #333333;
}

.light-mode .podcast-card h3 {
  color: #333333;
}

.light-mode .podcast-views {
  color: #666666;
}

.light-mode .podcast-description {
  color: #666666;
}

.light-mode .podcast-stats {
  color: #666666;
  border-top: 1px solid #eaeaea;
}

/* Dark mode card styles */
.dark-mode .podcast-card {
  background: #282828;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.dark-mode .podcast-info {
  background: #282828;
  color: #ffffff;
}

.dark-mode .podcast-card h3 {
  color: #ffffff;
}

.dark-mode .podcast-views {
  color: rgba(255, 255, 255, 0.7);
}

.dark-mode .podcast-description {
  color: rgba(255, 255, 255, 0.7);
}

.dark-mode .podcast-stats {
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Common card styles */
.podcast-image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.podcast-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.podcast-rating-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #1DB954;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.podcast-info {
  padding: 16px;
}

.podcast-card h3 {
  font-size: 1.1rem;
  margin: 0 0 8px 0;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.podcast-views {
  font-size: 0.9rem;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.podcast-description {
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 8px 0;
  line-height: 1.4;
}

.podcast-stats {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 0.85rem;
  margin-top: 12px;
  padding-top: 12px;
}

/* Hover effects */
.podcast-card:hover {
  transform: translateY(-5px);
}

.light-mode .podcast-card:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.dark-mode .podcast-card:hover {
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

/* Section header */
.section-header {
  text-align: center;
  margin: 40px 0 20px;
}

.section-header h2 {
  color: #1DB954;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

/* Responsive layout */
@media (max-width: 1600px) {
  .podcast-grid {
    padding: 20px;
  }
}

@media (max-width: 1200px) {
  .podcast-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 900px) {
  .podcast-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  .podcast-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    gap: 10px;
  }
}

/* Modal styles */
.podcast-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.podcast-details-content {
  background: white;
  border-radius: 12px;
  padding: 30px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.dark-mode .podcast-details-content {
  background: #1a1a1a;
  color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* RTL Support */
.App[dir="rtl"] {
  text-align: right;
}

.App[dir="rtl"] .podcast-card {
  text-align: right;
}

.App[dir="rtl"] .podcast-description {
  text-align: right;
}

.App[dir="rtl"] .podcast-stats {
  direction: rtl;
}

/* Footer */
.footer {
  background: #282828;
  color: white;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #1DB954;
}

:root {
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}

.App.dark-mode {
  background-color: #121212;
}

.App.dark-mode .podcast-card,
.App.dark-mode .chart-section,
.App.dark-mode .podcast-table-section {
  background-color: #282828;
  color: #ffffff;
}

.App.dark-mode .podcast-card h3 {
  color: #ffffff;
}

.App.dark-mode .podcast-description,
.App.dark-mode .podcast-stats,
.App.dark-mode .podcast-views,
.App.dark-mode .episode-stats,
.App.dark-mode .episode-item {
  color: #b3b3b3;
}

.App.dark-mode .search-input,
.App.dark-mode .podcast-limit,
.App.dark-mode select {
  background-color: #282828;
  color: #ffffff;
  border-color: #404040;
}

.App.dark-mode .search-input::placeholder {
  color: #b3b3b3;
}

.App.dark-mode .chart-section,
.App.dark-mode .podcast-table-section {
  background-color: #282828;
  color: #ffffff;
}

.App.dark-mode .podcast-table th {
  color: #ffffff;
}

.App.dark-mode .podcast-table td {
  color: #b3b3b3;
}

.App.dark-mode .section-header h2 {
  color: #ffffff;
}

.App.dark-mode .podcast-count {
  color: #b3b3b3;
}

.App {
  text-align: center;
  min-height: 100vh;
  background: linear-gradient(135deg, 
    #1a1b1f 0%,
    #2c2f36 25%,
    #232529 50%,
    #2c2f36 75%,
    #1a1b1f 100%
  );
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  position: relative;
}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 0%, 
    rgba(29, 185, 84, 0.15) 0%, 
    rgba(29, 185, 84, 0.05) 25%, 
    transparent 50%
  );
  pointer-events: none;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-header {
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
}

.App-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent, 
    rgba(29, 185, 84, 0.3), 
    transparent
  );
}

.App-header h1 {
  font-size: 3rem;
  font-weight: 800;
  color: #1db954;
  text-align: center;
  margin: 2rem 0;
  font-family: var(--font-primary);
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(45deg, #1db954, #1ed760, #1db954);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s ease infinite;
  text-shadow: 0 2px 10px rgba(29, 185, 84, 0.2);
}

.App-header h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, transparent, #1db954, transparent);
  border-radius: 2px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-header h1:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.search-form {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  max-width: 800px;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.search-input {
  flex: 1;
  padding: 12px 20px;
  border: 2px solid rgba(29, 185, 84, 0.3);
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #1db954;
  box-shadow: 0 0 0 3px rgba(29, 185, 84, 0.2);
}

.search-form button {
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  background: #1db954;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.search-form button:hover {
  background: #1ed760;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 185, 84, 0.3);
}

.search-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.search-controls form {
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 800px;
  width: 100%;
}

.search-controls input {
  flex: 1;
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  font-family: var(--font-primary);
  transition: border-color 0.2s ease;
}

.search-controls input:focus {
  outline: none;
  border-color: #1DB954;
}

.limit-selector {
  position: relative;
}

.limit-selector::after {
  content: '▼';
  font-size: 12px;
  color: #666;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.podcast-limit {
  padding: 12px 36px 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  font-family: var(--font-primary);
  background: white;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  transition: all 0.2s ease;
}

.podcast-limit:focus {
  outline: none;
  border-color: #1DB954;
}

.podcast-limit:hover {
  border-color: #1DB954;
}

.search-controls button {
  padding: 12px 24px;
  background: #1DB954;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.search-controls button:hover {
  background: #1ed760;
  transform: translateY(-1px);
}

.search-controls button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .search-controls form {
    flex-direction: column;
    padding: 0 16px;
  }

  .search-controls input,
  .podcast-limit,
  .search-controls button {
    width: 100%;
  }
}

.podcast-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.podcast-image-container {
  position: relative;
  width: 100%;
}

.podcast-image-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.podcast-rating-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(29, 185, 84, 0.95);
  padding: 8px 12px;
  border-radius: 20px;
  color: white;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-family: var(--font-primary);
}

.rating-value {
  font-size: 1.4em;
  font-weight: 700;
  line-height: 1;
}

.podcast-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.podcast-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.App.dark-mode .podcast-card {
  background-color: #282828;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.App.dark-mode .podcast-card:hover {
  background-color: #333333;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.podcast-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 12px 0 8px;
  line-height: 1.4;
  font-family: var(--font-primary);
}

.podcast-description {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #666;
  margin: 8px 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: var(--font-primary);
}

.podcast-stats {
  font-size: 0.9rem;
  font-weight: 500;
  color: #666;
  font-family: var(--font-primary);
}

.podcast-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.podcast-rating {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1DB954;
  font-family: var(--font-primary);
}

.podcast-views {
  color: #666;
  font-size: 0.9em;
  font-family: var(--font-primary);
}

.podcast-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.episode-stats {
  display: flex;
  gap: 15px;
  color: #666;
  font-size: 0.9em;
  margin: 8px 0;
  font-family: var(--font-primary);
}

.episode-item {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
}

.episode-item h4 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1DB954;
  margin: 0 0 8px;
  line-height: 1.4;
  font-family: var(--font-primary);
}

.episode-meta {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  color: #666;
  font-size: 0.9rem;
  font-family: var(--font-primary);
}

.listen-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #1DB954;
  color: white;
  padding: 12px 20px;
  border-radius: 24px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  margin-top: 12px;
  box-shadow: 0 2px 4px rgba(29, 185, 84, 0.2);
}

.listen-button:hover {
  background: #1ed760;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(29, 185, 84, 0.3);
}

.listen-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(29, 185, 84, 0.2);
}

.listen-button svg {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease;
}

.listen-button:hover svg {
  transform: scale(1.1);
}

.listen-button span {
  font-family: var(--font-primary);
  letter-spacing: 0.02em;
}

.ad-container-horizontal {
  width: 100%;
  max-width: 728px;
  margin: 20px auto;
  min-height: 90px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .ad-container-horizontal {
    max-width: 100%;
    margin: 10px 0;
  }
}

/* Responsive ad containers */
@media (min-width: 728px) {
  .ad-container {
    max-width: 728px;
  }
}

@media (max-width: 727px) {
  .ad-container {
    max-width: 320px;
  }
}

/* Ad Container Styles */
.ad-container {
  width: 100%;
  margin: 20px auto;
  text-align: center;
  min-height: 100px;
  background-color: transparent;
  overflow: hidden;
}

.ad-container ins.adsbygoogle {
  display: block;
  margin: 0 auto;
}

.chart-section {
  margin-top: 20px;
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.chart-section h2 {
  text-align: center;
  margin-bottom: 0.5rem;
  color: #1db954;
  font-size: 1.4rem;
  font-family: var(--font-primary);
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

@media (max-width: 1024px) {
  .charts-container {
    grid-template-columns: 1fr;
  }
}

.recharts-legend-wrapper {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}

.recharts-legend-item {
  display: inline-flex !important;
  align-items: center;
  margin: 0 8px !important;
}

.recharts-legend-item-text {
  margin-left: 6px;
  font-family: var(--font-primary);
  font-size: 0.9rem;
  color: #666;
}

section h2 {
  color: #282c34;
  margin-bottom: 20px;
  font-family: var(--font-primary);
}

.error-message {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.error-message h2 {
  color: #d32f2f;
  margin-bottom: 1rem;
  font-family: var(--font-primary);
}

.error-message p {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-family: var(--font-primary);
}

.error-message ol {
  margin-left: 1.5rem;
  line-height: 1.6;
  font-family: var(--font-primary);
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.custom-tooltip p {
  margin: 4px 0;
  font-size: 14px;
  font-family: var(--font-primary);
}

.custom-tooltip .podcast-name {
  font-weight: bold;
  color: #1db954;
  margin-bottom: 8px;
  font-family: var(--font-primary);
}

.custom-tooltip .episode-count {
  color: #666;
  font-family: var(--font-primary);
}

.custom-tooltip .publisher {
  color: #888;
  font-style: italic;
  font-family: var(--font-primary);
}

.custom-tooltip .rating {
  color: #f1c40f;
  font-weight: bold;
  margin-top: 8px;
  font-family: var(--font-primary);
}

.podcast-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.2s ease-out;
}

.podcast-details-content {
  background: white;
  border-radius: 12px;
  padding: 30px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.3s ease-out;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #333;
}

.episodes-list {
  margin-top: 2rem;
}

.episode-item {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background: #f9f9f9;
}

.episode-item h4 {
  margin: 0 0 0.5rem 0;
  color: #1db954;
  font-family: var(--font-primary);
}

.episode-meta {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  color: #666;
  font-size: 0.9rem;
  font-family: var(--font-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #1a1a1a;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.25rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

p {
  font-family: var(--font-primary);
  font-weight: 400;
  line-height: 1.6;
  color: #4a4a4a;
}

.section-header {
  text-align: center;
  margin-bottom: 2.5rem;
  position: relative;
}

.section-header h2 {
  font-size: 2.2rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.title-text {
  background: linear-gradient(45deg, #1DB954, #1ed760);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
  display: inline-block;
}

.podcast-count {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  opacity: 0.9;
}

.header-underline {
  width: 60px;
  height: 4px;
  background: linear-gradient(45deg, #1DB954, #1ed760);
  border-radius: 2px;
  margin: 1rem auto 0;
  position: relative;
}

.header-underline::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(29, 185, 84, 0.2);
  border-radius: 1px;
}

@media (max-width: 768px) {
  .section-header h2 {
    font-size: 1.8rem;
  }

  .podcast-count {
    font-size: 0.9rem;
  }

  .header-underline {
    width: 40px;
    height: 3px;
  }
}

.podcast-table-section {
  margin-top: 40px;
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.podcast-table-section h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: #1db954;
  font-size: 1.8rem;
  font-family: var(--font-primary);
}

.podcast-table {
  width: 100%;
  overflow-x: auto;
}

.podcast-table table {
  width: 100%;
  border-collapse: collapse;
  font-family: var(--font-primary);
}

.podcast-table th {
  background: #f8f8f8;
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #eee;
  white-space: nowrap;
}

.podcast-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  color: #666;
  vertical-align: middle;
}

.podcast-table tr:hover {
  background-color: #f9f9f9;
}

.color-indicator {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  margin: 0 auto;
}

.podcast-name-cell {
  display: flex;
  align-items: center;
  gap: 12px;
}

.podcast-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: cover;
}

.explicit-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.explicit-yes {
  background-color: #ff4444;
  color: white;
}

.explicit-no {
  background-color: #4CAF50;
  color: white;
}

.spotify-link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #1db954;
  color: white;
  text-decoration: none;
  border-radius: 20px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.spotify-link:hover {
  background-color: #1ed760;
}

.spotify-link svg {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .podcast-table {
    font-size: 14px;
  }

  .podcast-table th,
  .podcast-table td {
    padding: 8px;
  }

  .color-indicator {
    width: 20px;
    height: 20px;
  }

  .podcast-thumbnail {
    width: 32px;
    height: 32px;
  }

  .spotify-link {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.sortable-header {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-right: 25px !important;
  transition: background-color 0.2s;
}

.sortable-header:hover {
  background-color: #f0f0f0;
}

.sort-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  opacity: 0.6;
}

.sortable-header:hover .sort-icon {
  opacity: 1;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.logo-container h1 {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  font-weight: 800;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
}

.spotify-text {
  background: linear-gradient(45deg, #1db954, #1ed760);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.podcast-text {
  font-size: clamp(1.3rem, 4.5vw, 2.2rem);
  color: #333;
  font-weight: 700;
  font-size: 2.2rem;
}

.explorer-text {
  font-size: clamp(1.2rem, 4vw, 2rem);
  color: #666;
  font-weight: 600;
  font-size: 2rem;
}

.theme-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: transform 0.3s ease;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

.theme-toggle:hover {
  transform: translateY(-50%) scale(1.1);
}

.App.dark-mode .podcast-text {
  color: #ffffff;
}

.App.dark-mode .explorer-text {
  color: #b3b3b3;
}

.App.dark-mode .search-form button,
.App.dark-mode .load-more {
  background: #1db954;
  color: white;
}

.App.dark-mode .search-form button:hover,
.App.dark-mode .load-more:hover {
  background: #1ed760;
}

.App.dark-mode .search-input:hover,
.App.dark-mode .search-input:focus,
.App.dark-mode .podcast-limit:hover,
.App.dark-mode .podcast-limit:focus,
.App.dark-mode select:hover,
.App.dark-mode select:focus {
  border-color: #1db954;
  background-color: #333333;
}

.App.dark-mode .podcast-table tr:hover {
  background-color: #333333;
}

.App.dark-mode .theme-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    padding: 1rem;
  }

  .theme-toggle {
    right: 1rem;
  }

  .search-form {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  .search-input {
    width: 100%;
    max-width: none;
  }

  .podcast-limit {
    width: 100%;
  }

  .search-form button {
    width: 100%;
  }

  .charts-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .chart-section {
    padding: 1rem;
    min-height: auto;
  }

  .podcast-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }

  .podcast-card {
    padding: 0.8rem;
  }

  .podcast-card h3 {
    font-size: 1rem;
    margin: 8px 0 4px;
  }

  .podcast-description {
    font-size: 0.8rem;
    -webkit-line-clamp: 2;
  }

  .podcast-stats {
    font-size: 0.8rem;
  }

  .podcast-image-container img {
    height: 120px;
  }

  .podcast-table-section {
    padding: 1rem;
    margin: 1rem;
    overflow-x: auto;
  }

  .podcast-table {
    font-size: 0.8rem;
  }

  .podcast-table th,
  .podcast-table td {
    padding: 8px;
  }
}

/* Small phones */
@media (max-width: 480px) {
  .logo-container h1 {
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
  }

  .podcast-grid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }

  .podcast-card {
    max-width: 300px;
    margin: 0 auto;
  }

  .search-section {
    padding: 0.5rem;
  }

  .chart-section {
    margin: 0.5rem;
  }
}

/* Table styles for dark mode */
.App.dark-mode .podcast-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  background: #2c2c2c;
  border: 1px solid #444;
}

.App.dark-mode .podcast-table thead {
  background: #1e1e1e;
  border-bottom: 2px solid #444;
}

.App.dark-mode .podcast-table th {
  background: #1e1e1e;
  color: #00ff9d;
  font-weight: 600;
  text-align: left;
  padding: 12px;
  border: 1px solid #444;
  position: sticky;
  top: 0;
  z-index: 1;
}

.App.dark-mode .podcast-table td {
  padding: 12px;
  border: 1px solid #444;
  color: #ffffff;
  background: #2c2c2c;
}

.App.dark-mode .podcast-table tbody tr:nth-child(even) {
  background: #363636;
}

.App.dark-mode .podcast-table tbody tr:hover {
  background: #404040;
  cursor: pointer;
}

.App.dark-mode .podcast-table tbody tr td {
  transition: background-color 0.2s ease;
}

/* Sort header styles */
.App.dark-mode .podcast-table th.sortable {
  cursor: pointer;
  user-select: none;
}

.App.dark-mode .podcast-table th.sortable:hover {
  background: #2c2c2c;
}

.App.dark-mode .podcast-table th.sortable::after {
  content: '↕';
  margin-left: 5px;
  color: #00ff9d;
  opacity: 0.5;
}

.App.dark-mode .podcast-table th.sortable.asc::after {
  content: '↑';
  opacity: 1;
}

.App.dark-mode .podcast-table th.sortable.desc::after {
  content: '↓';
  opacity: 1;
}

/* Sort icon styles for dark mode */
.App.dark-mode .podcast-table th.sortable {
  cursor: pointer;
  position: relative;
  padding-right: 25px;
}

.App.dark-mode .podcast-table th.sortable:hover {
  background: #2c2c2c;
}

.App.dark-mode .podcast-table th.sortable .sort-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #00ff9d;
  opacity: 0.5;
  font-size: 14px;
}

.App.dark-mode .podcast-table th.sortable .sort-icon.active {
  opacity: 1;
}

/* Explicit tag styles in dark mode */
.App.dark-mode .explicit-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85em;
}

.App.dark-mode .explicit-tag.explicit-yes {
  background: #ff4444;
  color: white;
}

.App.dark-mode .explicit-tag.explicit-no {
  background: #45a049;
  color: white;
}

/* Spotify link styles in dark mode */
.App.dark-mode .spotify-link {
  background: #1DB954;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.2s ease;
}

.App.dark-mode .spotify-link:hover {
  background: #1ed760;
}

.spotify-link svg {
  width: 16px;
  height: 16px;
}

.app-subtitle {
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
  color: #666;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.02em;
}

.App.dark-mode .app-subtitle {
  color: #aaa;
}

/* RTL Support */
.App[dir="rtl"] {
  text-align: right;
}

.App[dir="rtl"] .search-form {
  direction: rtl;
}

.App[dir="rtl"] .search-input {
  text-align: right;
}

.App[dir="rtl"] .podcast-card {
  text-align: right;
}

.App[dir="rtl"] .podcast-description {
  text-align: right;
}

.App[dir="rtl"] .podcast-stats,
.App[dir="rtl"] .podcast-views,
.App[dir="rtl"] .episode-stats {
  direction: rtl;
}

.App[dir="rtl"] .podcast-table th,
.App[dir="rtl"] .podcast-table td {
  text-align: right;
}

.App[dir="rtl"] .podcast-details-content {
  text-align: right;
}

.App[dir="rtl"] .close-button {
  left: 20px;
  right: auto;
}

.App[dir="rtl"] .limit-selector::after {
  left: 12px;
  right: auto;
}

.App[dir="rtl"] .podcast-limit {
  padding: 12px 16px 12px 36px;
}

.App[dir="rtl"] .sort-icon {
  left: 8px;
  right: auto;
}

.App[dir="rtl"] .sortable-header {
  padding-left: 25px !important;
  padding-right: 12px !important;
}

@media (max-width: 768px) {
  .App[dir="rtl"] .search-controls form {
    align-items: flex-end;
  }
}

/* Privacy Policy Styles */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  text-align: center;
  color: #1DB954;
  margin-bottom: 30px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: #333;
  margin-bottom: 15px;
}

.privacy-policy p {
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

/* Footer Styles */
.footer {
  background: #282828;
  color: white;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #1DB954;
}

.app-subtitle {
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
  color: #666;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.02em;
}

.App.dark-mode .app-subtitle {
  color: #aaa;
}

/* RTL Support */
.App[dir="rtl"] {
  text-align: right;
}

.App[dir="rtl"] .search-form {
  direction: rtl;
}

.App[dir="rtl"] .search-input {
  text-align: right;
}

.App[dir="rtl"] .podcast-card {
  text-align: right;
}

.App[dir="rtl"] .podcast-description {
  text-align: right;
}

.App[dir="rtl"] .podcast-stats,
.App[dir="rtl"] .podcast-views,
.App[dir="rtl"] .episode-stats {
  direction: rtl;
}

.App[dir="rtl"] .podcast-table th,
.App[dir="rtl"] .podcast-table td {
  text-align: right;
}

.App[dir="rtl"] .podcast-details-content {
  text-align: right;
}

.App[dir="rtl"] .close-button {
  left: 20px;
  right: auto;
}

.App[dir="rtl"] .limit-selector::after {
  left: 12px;
  right: auto;
}

.App[dir="rtl"] .podcast-limit {
  padding: 12px 16px 12px 36px;
}

.App[dir="rtl"] .sort-icon {
  left: 8px;
  right: auto;
}

.App[dir="rtl"] .sortable-header {
  padding-left: 25px !important;
  padding-right: 12px !important;
}

@media (max-width: 768px) {
  .App[dir="rtl"] .search-controls form {
    align-items: flex-end;
  }
}

/* Privacy Policy Styles */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  text-align: center;
  color: #1DB954;
  margin-bottom: 30px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: #333;
  margin-bottom: 15px;
}

.privacy-policy p {
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

/* Footer Styles */
.footer {
  background: #282828;
  color: white;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #1DB954;
}

.app-subtitle {
  margin: 0.5rem 0 1rem;
  font-size: 1rem;
  color: #666;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.02em;
}

.App.dark-mode .app-subtitle {
  color: #aaa;
}

/* RTL Support */
.App[dir="rtl"] {
  text-align: right;
}

.App[dir="rtl"] .search-form {
  direction: rtl;
}

.App[dir="rtl"] .search-input {
  text-align: right;
}

.App[dir="rtl"] .podcast-card {
  text-align: right;
}

.App[dir="rtl"] .podcast-description {
  text-align: right;
}

.App[dir="rtl"] .podcast-stats,
.App[dir="rtl"] .podcast-views,
.App[dir="rtl"] .episode-stats {
  direction: rtl;
}

.App[dir="rtl"] .podcast-table th,
.App[dir="rtl"] .podcast-table td {
  text-align: right;
}

.App[dir="rtl"] .podcast-details-content {
  text-align: right;
}

.App[dir="rtl"] .close-button {
  left: 20px;
  right: auto;
}

.App[dir="rtl"] .limit-selector::after {
  left: 12px;
  right: auto;
}

.App[dir="rtl"] .podcast-limit {
  padding: 12px 16px 12px 36px;
}

.App[dir="rtl"] .sort-icon {
  left: 8px;
  right: auto;
}

.App[dir="rtl"] .sortable-header {
  padding-left: 25px !important;
  padding-right: 12px !important;
}

@media (max-width: 768px) {
  .App[dir="rtl"] .search-controls form {
    align-items: flex-end;
  }
}

/* Privacy Policy Styles */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  text-align: center;
  color: #1DB954;
  margin-bottom: 30px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: #333;
  margin-bottom: 15px;
}

.privacy-policy p {
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

/* Footer Styles */
.footer {
  background: #282828;
  color: white;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #1DB954;
}
